/**
 * Calculates the percentage difference between the
 * starting value and the current value
 */
export function calculatePercentageChange(
  startValue: number,
  currentValue: number
) {
  if (startValue === 0 || currentValue === 0) {
    return 0
  }

  return ((currentValue - startValue) / startValue) * 100
}
